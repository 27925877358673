<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
  

    <router-view />


  </div>
</template>

<script>

export default {
  setup() {

    return { contentWidth:null }
  },methods: {

 
}
}
</script>

<style >

</style>
